* {
    font-family: 'Microsoft YaHei' !important;
    margin: 0px;
    padding:0px;
}

body {
    min-width: 1280px;
}

::-webkit-scrollbar {
    width: 5px;
    height: 4px;
    border-radius: 10px;
    background-color: transparent;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgba(245,245,245, .3);
}